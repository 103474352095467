import './App.css';
import {Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Gems from './pages/Gems';
import GemsCreate from './pages/GemsCreate';
import GemsUpdate from './pages/GemsUpdate';
import GemsUpload from './pages/GemUpload';
import GemsListing from './pages/GemsListing';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import Preview from './pages/Preview';
import VisitorLog from './pages/VisitorLog.js';
function App() {
    return (
        <div className="App">
            <Navbar/>
            <Routes>
                <Route path="/" element={<SignIn />}/>
                <Route path="/gems" element={<Gems />}/>
                <Route path="create" element={<GemsCreate />}/>
                <Route path="update/:id" element={<GemsUpdate />}/>
                <Route path="listing/:id" element={<GemsListing />}/>
                <Route path="gemupload" element={<GemsUpload />}/>
                <Route path="signin" element={<SignIn />}/>
                <Route path="signup" element={<SignUp />}/>
                <Route path="dashboard" element={<Dashboard />}/>
                <Route path="preview/:id" element={<Preview />}/>
                <Route path="visitorlog" element={<VisitorLog />}/>
            </Routes>

        </div>
    );
}

export default App;

import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FileUpload from '../components/FileUpload';

export default function GemsUpload() {

    const [itemData, setItemData] = useState([]);

    useEffect(() => {
        ImagesGet()
    }, [])

    const ImagesGet = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
        );
        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        };
        fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
            .then(
                res => res.json()
            )
            .then((result) => {
                setItemData(result)
            })
            .catch(error => console.log('error', error));

    }

    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>
                <Typography variant='h6' gutterBottom="gutterBottom" component='div'>
                    Create Gem
                </Typography>
                <FileUpload/>

                <Typography variant='h6' gutterBottom="gutterBottom" component='div'>
                    Example Fatch Photo List
                </Typography>
                <ImageList
                    sx={{
                        width: 500,
                        height: 500
                    }}
                    cols={3}
                    rowHeight={164}>

                    {
                        itemData.map((item) => (

                            <ImageListItem key={item.media_details.sizes.thumbnail.source_url}>

                                <img src={item.media_details.sizes.thumbnail.source_url}/>

                            </ImageListItem>
                        ))
                    }
                </ImageList>

            </Container>
        </React.Fragment>
    );
}
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom'
import createRoot from 'react-dom'
import parse from 'html-react-parser';
import {json, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import axios from 'axios';
import xml2js from 'xml2js';
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';
import {CreateTitle,CreateParagrahp,CreateWooDescription,createXmlEbay,PriceOption,CreateEbayDescription} from '../functions/functions';



export default function GemsListing() {
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState('');
    const [seller, setSeller] = useState('');
    const [images, setImages] = useState('');
    const [videos, setVideos] = useState('');
    const [certimgs, setCertimgs] = useState('');
    const [woo, setWoo] = useState('');
    const [wooid, setWooID] = useState('');
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');


    const [imageDisplay, setImageDisplay] = useState([]);
    const [videoDisplay, setVideoDisplay] = useState([]);
    const [certDisplay, setCertDisplay] = useState([]);

    const [isUploading,setIsuploading] = useState(false);
    const [status,setStatus] = useState('');
    const [websiteURL,setWebsiteURL] = useState('');
    const [isListToWeb,setListToWeb] = useState(false);

    const [checked, setChecked] = useState(false);
    
    const [accesstoken, seAccesstoken] = useState("");
    const [accesstoken_isValid, setAccesstoken_isValid] = useState("");
    const [accesstoken_expirein, seAccesstoken_expirein] = useState("");
    const [data, setData] = useState(null);


    const {id} = useParams();

    useEffect(() => {
        //alert(id)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/" + id, requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {

                    //setId(result['gem']['id'])
                    setType(result['gem']['type'])
                    setWeight(result['gem']['weight'])
                    setShape(result['gem']['shape'])
                    setWidth(result['gem']['width'])
                    setLength(result['gem']['length'])
                    setDepth(result['gem']['depth'])
                    setColor(result['gem']['color'])
                    setClarity(result['gem']['clarity'])
                    setOrigin(result['gem']['origin'])
                    setTreatment(result['gem']['treatment'])
                    setCertificate(result['gem']['certificate'])
                    setSeller(result['gem']['seller'])
                    setImages(result['gem']['images'])
                    setVideos(result['gem']['videos'])
                    setCertimgs(result['gem']['certimgs'])
                    setPrice(result['gem']['price'])
                    setPriceOption(result['gem']['priceOption'])
                    setTitle(result['gem']['title'])
                    setDescription(result['gem']['description'])

                    setWoo(result['gem']['woo'])
                    if (typeof result['gem']['woo'] == 'object' & result['gem']['woo'] != null){
                        //console.log(result['gem']['woo']);
                        setListToWeb(true);
                        setWebsiteURL(result['gem']['woo'].permalink);
                        setWooID(result['gem']['woo'].id)

                    } else if(typeof result['gem']['woo'] == 'undefined'){
                        setListToWeb(false);
                    }else if(result['gem']['woo'] = null){
                        setListToWeb(false);
                    }
                    
                    setImageDisplay(result['gem']['images'])
                    setVideoDisplay(result['gem']['videos'])
                    setCertDisplay(result['gem']['certimgs'])

                if (typeof result['gem']['woo'] == 'object' & result['gem']['woo'] != null){
                    //console.log(result['gem']['woo']['status']);
                    if(result['gem']['woo']['status']=="private"){
                        setChecked(false);

                    }else if(result['gem']['woo']['status']=="publish"){
                        setChecked(true);
                    }
                }

                }
            })
            .catch(error => console.log('error', error));

            getAccessToken()


    }, [id])

    const getAccessToken = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          fetch("https://app.gemnoble.com:2005/auth/ebay/accesstoken", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                //console.log(result.token);
                seAccesstoken(result.token);
                setAccesstoken_isValid(result.isValid);
                seAccesstoken_expirein(result.expire_in);
                const interval = setInterval(() => {
                    seAccesstoken_expirein((accesstoken_expirein) => accesstoken_expirein - 1);
                  }, 1000);
              
                  return () => clearInterval(interval);
            })
            .catch((error) => console.error(error));
    }



    const  gem = {
        "id":id,
        "seller": seller,
        "type": type,
        "weight": weight,
        "shape": shape,
        "width": width,
        "length": length,
        "depth": depth,
        "color": color,
        "clarity": clarity,
        "origin": origin,
        "treatment": treatment,
        "certificate": certificate,
        "images": images,
        "videos": videos,
        "certimgs": certimgs,
        "price":price,
        "priceOption":priceOption,
        "woo":woo,
        "title":title,
        "description":description
        
    };

        const listToWeb =  (gem) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": "private",
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].value,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": shapeLists[shapeLists.findIndex(shapeLists => shapeLists.key === gem.shape)].value
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": colorLists[colorLists.findIndex(colorLists => colorLists.key === gem.color)].value
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": clarityLists[clarityLists.findIndex(clarityLists => clarityLists.key === gem.clarity)].value
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": originLists[originLists.findIndex(originLists => originLists.key === gem.origin)].value
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": treatmentLists[treatmentLists.findIndex(treatmentLists => treatmentLists.key === gem.treatment)].value
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [
                    /*
                    {
                    "id": 15636,
                    "key": "offers_for_woocommerce_enabled",
                    "value": "yes"
                    },
                    */
                
                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow'
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/", requestOptions)
                .then(response => response.text())
                .then(result => {
                    resolve(JSON.parse(result));
                    })
                .catch(error => reject(error));
            


        })
    }

    const updateToWeb =  (gem,id) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": checked?("publish"):("private"),
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].value,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": shapeLists[shapeLists.findIndex(shapeLists => shapeLists.key === gem.shape)].value
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": colorLists[colorLists.findIndex(colorLists => colorLists.key === gem.color)].value
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": clarityLists[clarityLists.findIndex(clarityLists => clarityLists.key === gem.clarity)].value
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": originLists[originLists.findIndex(originLists => originLists.key === gem.origin)].value
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": treatmentLists[treatmentLists.findIndex(treatmentLists => treatmentLists.key === gem.treatment)].value
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [

                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow',
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id, requestOptions)
                .then(response => response.text())
                .then(result => {
                        /*
                        if(JSON.parse(result).data.status = 400){
                            reject(JSON.parse(result).message)
                        }else {
                        resolve(JSON.parse(result));
                        }*/
                    
                    resolve(JSON.parse(result));
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                }
                    );
            


        })
    }

    const updateVisible =  (gem,id,visivlestatus) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": visivlestatus,
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gem.type,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": gem.shape
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": gem.color
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": gem.clarity
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": gem.origin
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": gem.treatment
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [

                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow',
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id, requestOptions)
                .then(response => response.text())
                .then(result => {
                        /*
                        if(JSON.parse(result).data.status = 400){
                            reject(JSON.parse(result).message)
                        }else {
                        resolve(JSON.parse(result));
                        }*/
                    
                    resolve(JSON.parse(result));
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                }
                    );
            


        })
    }



    const handleConsolelog = e => {
        console.log(gem);
        //console.log(PriceOption(gem.priceOption));
    }



    const handleUpdateWoo = (woo) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                console.log(result)
            })
            .catch(error => console.log('error', error));

    }

    const daleteToWeb = (id) => {
        return new Promise(async (resolve,reject)=>{
        var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                //myHeaders.append("Access-Control-Allow-Origin", "No");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };
                fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id+"?force=1", requestOptions)
                .then(response => response.text())
                .then(result => {
                    resolve(JSON.parse(result));
                    })
                .catch(error => reject(error));
        })
    }

    const handleDeleteToWeb = ()=>{
    
            setIsuploading(true);
            setStatus('Deleting ...');
            daleteToWeb(gem.woo.id)
            .then(data=>{
                setIsuploading(false);
                setStatus('');
                
                console.log(data);
                setListToWeb(false);
                handleUpdateWoo(null);
                Swal.fire({
                    icon: "success",
                    text: 'Deleted Successfully'
                    
                  })
                
                //setWebsiteURL(data.permalink);
                //setWooID(data.id);
                //setWoo(data);
            })
            .catch(error=>{
                setIsuploading(false);
                console.error(error);
                setStatus('error', error);
                //alert(error)
            
            })
            
        }


    const handleUpdateToWeb = ()=>{
    //console.log("Update ID : "+gem.woo.id)
        setIsuploading(true);
        setStatus('Updating to Web...');
        updateToWeb(gem,gem.woo.id)
        .then(data=>{
            setIsuploading(false);
            setStatus('');
            handleUpdateWoo(data);
            setWebsiteURL(data.permalink);
            setWooID(data.id);
            setWoo(data);
            Swal.fire({
                icon: "success",
                text: 'Updated Successfully'
                
              })
        })
        .catch(error=>{
            setIsuploading(false);
            console.error(error);
            setStatus('error', error);
            //alert(error)
        
        })
        
    }



    const handleListToWeb = ()=>{
            setIsuploading(true);
            setStatus('Listing to Web...');
            listToWeb(gem)
            .then(data => {
                setIsuploading(false);
                if(typeof data == 'object') {
                    console.log(data);
                    setWebsiteURL(data.permalink);
                    handleUpdateWoo(data);
                    setStatus('');
                    setListToWeb(true);
                    setWooID(data.id);
                    setWoo(data);
                    if(data.status === 'publish'){
                        setChecked(true);
                    }else if (data.status ==='private'){
                        setChecked(false);
                    }
                    Swal.fire({
                        icon: "success",
                        text: 'Listed Successfully'
                        
                      })
                    } 
                
            })
            .catch(error => {
                setIsuploading(false);
                console.log(error);
            })

    }

    

    const handleGetAccount = async()=>{
    
        
        //console.log(createXmlEbay(gem,accesstoken));
        
                let data = JSON.stringify({
                    "url": "https://api.ebay.com/ws/api.dll",
                    "headers": {
                      "X-EBAY-API-SITEID": "0",
                      "X-EBAY-API-COMPATIBILITY-LEVEL": "967",  
                      "X-EBAY-API-CALL-NAME": "GetAccount",
                      "Content-Type": "text/xml",
                      "X-EBAY-API-DETAIL-LEVEL":"0",
                      "X-EBAY-API-IAF-TOKEN": accesstoken
                    },
                    "body": escape(`<?xml version="1.0" encoding="utf-8"?>
                                    <GetAccountRequest xmlns="urn:ebay:apis:eBLBaseComponents">    
                                        <ErrorLanguage>en_US</ErrorLanguage>
                                        <WarningLevel>High</WarningLevel>
                                        <!-- This call does not work in the Sandbox environment -->
                                    <AccountEntrySortType>AccountEntryFeeTypeAscending</AccountEntrySortType>
                                    <AccountHistorySelection>LastInvoice</AccountHistorySelection>
                                    </GetAccountRequest>`)
                                });
                  
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://app.gemnoble.com:2005/api/ebay/proxy',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios.request(config)
                  .then((response) => {
                    const parser = new xml2js.Parser({
                        explicitArray: false, 
                        mergeAttrs: true,    
                    });
                    parser.parseString(response.data, (err, result) => {
                        if (err) {
                            console.error('Error parsing XML:', err);
                            return;
                        }
                        ///res.status(200).send(result);
                        //console.log(result.GetAccountResponse);
                        if(result.GetAccountResponse.Ack ==='Success'){
                        Swal.fire({
                            icon: "success",
                            title: "ACK : "+result.GetAccountResponse.Ack,
                            text: 'Account State : '+ result.GetAccountResponse.AccountSummary.AccountState,
                            footer: 'AccountID = '+result.GetAccountResponse.AccountID
                            
                          })} else if(result.GetAccountResponse.Ack ==='Failure') {
                            Swal.fire({
                                icon: "error",
                                title: "ACK : "+result.GetAccountResponse.Ack,
                                text: 'Error : '+ result.GetAccountResponse.Errors.LongMessage
                                
                              })

                          }
                    });
                    
                    
                    //console.log(response.data)
                    //console.log((response.data));
                  })
                  .catch((error) => {
                    //console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Requst Error",
                        text: 'Error : '+ error
                        
                      })
                  });
                  
               
    }

    const handleListToEbay = async()=>{
    
        
        console.log(createXmlEbay(gem,accesstoken));
        
                let data = JSON.stringify({
                    "url": "https://api.ebay.com/ws/api.dll",
                    "headers": {
                      "X-EBAY-API-SITEID": "0",
                      "X-EBAY-API-COMPATIBILITY-LEVEL": "967",  
                      "X-EBAY-API-CALL-NAME": "AddItem",
                      "X-EBAY-API-IAF-TOKEN": accesstoken
                    },
                    "body": escape(createXmlEbay(gem,accesstoken)) });
                  
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://app.gemnoble.com:2005/api/ebay/proxy',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios.request(config)
                  .then((response) => {
                    const parser = new xml2js.Parser({
                        explicitArray: false, 
                        mergeAttrs: true,    
                    });
                    parser.parseString(response.data, (err, result) => {
                        if (err) {
                            console.error('Error parsing XML:', err);
                            return;
                        }
                        ///res.status(200).send(result);
                        console.log(result.AddItemResponse);
                        console.log(result.AddItemResponse.Ack);
                    });
                    //console.log(response.data)
                    //console.log((response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                  
               
    }

    
    const handleVisible = (event) => {
                setChecked(event.target.checked);
                if (checked==false){
                    setIsuploading(true);
                    setStatus('Updating to Web...');
                    updateVisible(gem,gem.woo.id,"publish")
                    .then(data=>{
                    setIsuploading(false);
                    setStatus('Updated Successfully');
                    handleUpdateWoo(data);
                    setWebsiteURL(data.permalink);
                    setWooID(data.id);
                    setWoo(data);
                })
                .catch(error=>{
                    setIsuploading(false);
                    console.error(error);
                    setStatus('error', error);
                    setChecked(true);
                
                })
                }else {
                    setIsuploading(true);
                    setStatus('Updating to Web...');
                    updateVisible(gem,gem.woo.id,"private")
                    .then(data=>{
                    setIsuploading(false);
                    setStatus('Updated Successfully');
                    handleUpdateWoo(data);
                    setWebsiteURL(data.permalink);
                    setWooID(data.id);
                    setWoo(data);
                })
                .catch(error=>{
                    setIsuploading(false);
                    console.error(error);
                    setStatus('error', error);
                    setChecked(false);
                
                })

                }
        
            };





    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>
                <Typography variant='h6' gutterBottom component='div'>
                    Update Gem ID : {id}
                </Typography>
                <form >
                    <Grid container spacing={2}>

                        
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="weight"
                                value={weight}
                                type="number"
                                label="Gem Weight"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Ct</InputAdornment>
                                }}
                                fullWidth
                                required
                                disabled
                                onChange={(e) => setWeight(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Shape</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="shape-select"
                                    value={shape}
                                    label="Seller"
                                    required
                                    disabled
                                    onChange={(e) => setShape(e.target.value)}>
                                    {
                                        shapeLists.map((shapelist) => (
                                            <MenuItem key={shapelist.key} value={shapelist.key}>{shapelist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="width"
                                value={width}
                                type="number"
                                label="Width"
                                variant="outlined"
                                disabled
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setWidth(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="length"
                                value={length}
                                type="number"
                                label="Length"
                                variant="outlined"
                                disabled
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setLength(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="depth"
                                value={depth}
                                type="number"
                                label="Depth"
                                variant="outlined"
                                disabled
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setDepth(e.target.value)}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="color-select"
                                    value={color}
                                    label="Color"
                                    required
                                    disabled
                                    onChange={(e) => setColor(e.target.value)}>
                                    {
                                        colorLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Clarity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="clarity-select"
                                    value={clarity}
                                    label="Clarity"
                                    required
                                    disabled
                                    onChange={(e) => setClarity(e.target.value)}>
                                    {
                                        clarityLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Origin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="origin-select"
                                    value={origin}
                                    label="Origin"
                                    required
                                    disabled
                                    onChange={(e) => setOrigin(e.target.value)}>
                                    {
                                        originLists.map((originlist) => (
                                            <MenuItem key={originlist.key} value={originlist.key}>{originlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Treathment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="treathment-select"
                                    value={treatment}
                                    label="Treathment"
                                    required
                                    disabled
                                    onChange={(e) => setTreatment(e.target.value)}>
                                    {
                                        treatmentLists.map(
                                            (heatlist) => (<MenuItem key={heatlist.key} value={heatlist.key}>{heatlist.value}</MenuItem>)
                                        )
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="certificate-multiple-checkbox"
                                label="Certificate"
                                multiple
                                fullWidth
                                disabled
                                value={typeof certificate === 'string'
                                ? certificate.split(',')
                                : certificate}
                                onChange={(e) => {
                                    const {target: {
                                            value
                                        }} = e;
                                    setCertificate(
                                        // On autofill we get a stringified value.
                                            typeof value === 'string'
                                            ? value.split(',')
                                            : value,
                                    );
                                }}
                                input={<OutlinedInput label = "" />}
                                renderValue={(selected) => selected.join(', ')}>
                                {
                                    certs.map((cert) => (
                                        <MenuItem key={cert} value={cert}>
                                            <Checkbox checked={certificate.indexOf(cert) > -1}/>
                                            <ListItemText primary={cert}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={price}
                                    required
                                    disabled
                                    onChange={e=>setPrice(e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                        <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group" >Price Option</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={priceOption}
                            disabled
                            required
                        >
                            <FormControlLabel disabled value="open" control={<Radio />} label="Open Price" />
                            <FormControlLabel disabled value="sale" control={<Radio />} label="Sale Price" />
                            
                        </RadioGroup>
                        </FormControl>

                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Images
                                </Typography>
                                <ImageList
                                    sx={{
                                        width: 500,
                                        height: 500,
                                        p: 2,
                                        border: '1px dashed grey'
                                    }}
                                    cols={3}
                                    rowHeight={164}>
                                    {
                                        imageDisplay.map((item) => (
                                            <ImageListItem key={item.slug}>
                                                <img src={item.media_details.sizes.woocommerce_thumbnail.source_url}/>
                                            </ImageListItem>
                                        ))
                                    }
                                    
                                </ImageList>
                                
                               

                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Videos
                                </Typography>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>

                                    {
                                        videoDisplay.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                key={item.id}>

                                                <CardCover>
                                                    <video autoPlay="autoPlay" loop="loop" muted="muted">
                                                        <source src={item.source_url} type="video/mp4"/>
                                                    </video>
                                                </CardCover>

                                            </Card>
                                        ))
                                    }
                                </Box>
                                
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Certificates
                                </Typography>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>
                                    {
                                        certDisplay.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                key={item.id}>
                                                <CardCover>
                                                    <img src={item.source_url} srcSet={item.source_url} loading="lazy" alt=""/>
                                                </CardCover>
                                            </Card>
                                        ))
                                    }
                                </Box>

                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                        

                          
                        </Grid>

                        <Grid item xs={12}>
                            
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey',
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Create title and description
                                </Typography>
                                <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                component="ul"
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                    p: 0,
                                    m: 0
                                }}>
                                    <TextField
                                    fullWidth 
                                    id="outlined-multiline-flexible"
                                    
                                    multiline
                                    maxRows={4}
                                    value={title}
                                    onChange={e=>{setTitle(e.target.value);}}
                                    disabled
                                    />
                                    </Box></Container>

                                    <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>
                                        <TextField
                                        fullWidth
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                        onChange={e=>{setDescription(e.target.value);}}
                                        disabled
                                        />
                                        </Box></Container>

                                
                        
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            
                                        </Grid>
                                        <Grid item xs={4}>
                                        <Button variant='outlined'  fullWidth startIcon={<EditIcon />} onClick={e=>{e.preventDefault();window.location.href = '/update/'+gem.id;}}>Edit</Button>
                                        </Grid>
                                        
                                        </Grid>
                                

                            </Container>
                        </Grid>

                       

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Listing to WooCommerce
                                </Typography>
                                
                            
                                {
                                    isListToWeb?(
                                    <Container >
                                        <Typography variant='p' gutterBottom component='div'>
                                            Woo Commerce ID : <a href={websiteURL} target="_blank">{wooid}</a>
                                        </Typography>
                                        <Typography variant='p' gutterBottom component='div'>
                                            .
                                        </Typography>
                                       
                                        
                                            
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                onClick={handleUpdateToWeb}
                                                variant='contained'
                                                fullWidth
                                                >Update 
                                            </Button>
                                            <Button color="error"
                                                onClick={handleDeleteToWeb}
                                                variant='contained'
                                                fullWidth
                                                >DELETE
                                            </Button>
                                            <Switch  checked={checked}
                                                     onChange={handleVisible}
                                                     disabled={isUploading}
                                                     
                                                     />
                                               
                                            </Stack>
                                            
                                           
                                       
                                      
                                    
                                   
                                    </Container>

                                    
                                    
                                        )
                                    :(<Button
                                        onClick={handleListToWeb}
                                        variant='contained'
                                        fullWidth
                                        >List to Website</Button>)
                                }
                                <Box sx={{ width: '100%' }}>
                                    <Typography>
                                        {status}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                    {isUploading?(<LinearProgress />):(<div></div>)}
                                    
                                    </Box>

                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                            

                               

                                

                               
                                

                            </Container>
                        

                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Listing to eBay
                                </Typography>
                                {accesstoken_isValid?(<Typography variant='p' gutterBottom component='div'>
                                    Access Token Expire in : {accesstoken_expirein} Second
                                        </Typography>):(<Typography variant='p' gutterBottom component='div'>
                                            <a href={"https://app.gemnoble.com:2005/auth/ebay/"} target="_blank">{"Obtain token"}</a>
                                        </Typography>)}
                                
                                        
                                <Typography variant='p' gutterBottom component='div'>
                                    Product URL : 
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                            <Button
                                                    onClick={e=>{e.preventDefault();window.location.href = '/preview/'+gem.id;}}
                                                    variant='contained'
                                                    fullWidth
                                                    >PreView Description
                                            </Button>
                                            <Button
                                                    onClick={handleGetAccount}
                                                    variant='contained'
                                                    fullWidth
                                                    >Check Account Status
                                            </Button>
                            
                                        </Stack>
                                
                                
                                    
                                

                            </Container>
                        </Grid>
                        
      

                        <Grid item xs={12}>
                        {true?(<div></div>):(<Button
                                onClick={handleConsolelog}
                                variant='contained'
                                fullWidth
                                >CONSOLELOG</Button>)}
                            
                        </Grid>


                    </Grid>
                </form>
                
            </Container>
        </React.Fragment>
    );
}  
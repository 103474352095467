
const WordpressToken = "c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg==";


export const deleteMedia = async (mediaID) => {
    console.log("Deleting Media ID : " + mediaID)
    return new Promise(async (resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + WordpressToken)
        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow"
        };
        await fetch(
            "https://gemnoble.com/wp-json/wp/v2/media/" + mediaID + "?force=1",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                resolve(result);
                console.log(
                    "Media ID : " + JSON.parse(result).previous.id + " has Deleted"
                )
            })
            .catch((error) => {
                reject(error);
                console.error(error);
                console.log("Error on Deleting Media ID : " + mediaID);
            });
    })

}


export const getImagePlaceHolder = async () => {

    return new Promise(async (resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + WordpressToken)
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            mode:"no-cors"
        };
        await fetch("https://gemnoble.com/wp-json/wp/v2/media/30", requestOptions)
            .then(
                (response) => response.text()
            )
            .then((result) => {
                
                resolve(result);
            })
            .catch((error) => {
                reject(error);
                console.error(error);

            });
    })

}
import React, {useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2';

import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';
import {CreateTitle,CreateParagrahp} from '../functions/functions';

export default function GemsCreate() {

    const [seller, setSeller] = useState('');
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState([]);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [certimgs, setCertimgs] = useState([]);
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

  const handlePriceOption = (event) => {
    setPriceOption(event.target.value);
  };

    const gem = {
      "seller": seller,
      "type": type,
      "weight": weight,
      "shape": shape,
      "width": width,
      "length": length,
      "depth": depth,
      "color": color,
      "clarity": clarity,
      "origin": origin,
      "treatment": treatment,
      "certificate": certificate,
      "images": images,
      "videos": videos,
      "certimgs": certimgs,
      "price":price,
      "priceOption":priceOption,
      "title":title,
      "description":description

  };

    const [isImagesPicked, setIsImagesPicked] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const [imageFilesSelect, setImageFilesSelect] = useState([]);
    const [videoFilesSelect, setVideFilesSelect] = useState([]);
    const [certimgFilesSelect, setCertimgFileSelect] = useState([]);

    const [isUploading,setIsuploading] = useState(false);
    const [status,setStatus] = useState('');
    const [listedId,setListId] = useState('');


    const handleSelectImages = (e) => {
        setIsImagesPicked(true)
        console.log(e.target.files)
        setImageFilesSelect(Array.prototype.slice.call(e.target.files))
    };
    const handleSelectVideos = (e) => {
        setVideFilesSelect(Array.prototype.slice.call(e.target.files))
    };
    const handleSelectCerts = (e) => {
        setCertimgFileSelect(Array.prototype.slice.call(e.target.files))

    };



    const handleConsolelog = event => {
        console.log(gem)
    }
    const isTitleMorethan80 = (title) => {
        if(title.length>80){
            return true
        }else{
            return false
        }
    }


//Buffer.from('somprasong' + ':' + 'ParaibaGIA288').toString('base64')
//console.log(Buffer.from("SGVsbG8gV29ybGQ=", 'base64').toString('ascii'))

    const uploadImages = () => {
      return new Promise(async (resolve,reject)=>{
        const ImagesList = [];
        setIsuploading(true);
        for (let i = 0; i < imageFilesSelect.length; i++) {
            setStatus("Uploading Image : " + imageFilesSelect[i].name);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + imageFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: imageFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {
                    ImagesList.push(JSON.parse(result))
                    console.log(
                        "Uploaded : " + imageFilesSelect[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                    )
                })
                .catch(error => reject(error));
        }
        
        resolve(ImagesList);
        setIsuploading(false);
        setStatus("Uploaded Image done");
      })
    }

    const uploadVideos = () => {
      return new Promise(async (resolve,reject)=>{
        const VideosList = [];
        setIsuploading(true);
        for (let i = 0; i < videoFilesSelect.length; i++) {

            setStatus("Uploading Video : " + videoFilesSelect[i].name)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + videoFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: videoFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {

                    VideosList.push(JSON.parse(result))
                    console.log(
                        "Uploaded : " + videoFilesSelect[i].name + " URL:" + JSON.parse(result).source_url
                    )

                })
                .catch(error => reject(error));

        }
        resolve(VideosList);
        setIsuploading(false);
        setStatus("Uploaded Video done");

      })
    }

    const uploadCerts = () => {
      return new Promise(async (resolve,reject)=>{
        const CertsList = [];
        setIsuploading(true);
        for (let i = 0; i < certimgFilesSelect.length; i++) {

            setStatus("Uploading Certificate : " + certimgFilesSelect[i].name)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + certimgFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: certimgFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {
                  CertsList.push(JSON.parse(result))
                  console.log(
                        "Uploaded : " + certimgFilesSelect[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                    )

                })
                .catch(error => reject(error));

        }
        resolve(CertsList);
        setIsuploading(false);
        setStatus("Uploaded Certificate done");

      })
    }

    


    

    const handleSubmit = (event) => {
        event.preventDefault();
        if(isTitleMorethan80(title)){
            Swal.fire({
                icon: "error",
                title: "Title more than 80",
                text: 'Please edit title '
              })
            
        }
        else if (!isImagesPicked ){
          Swal.fire({
            icon: "error",
            title: "Please Select Photo",
            text: ' '
          })
        } else {
          var imagess = [];
          var videoss = [];
          var certimgss = [];

          uploadImages()
          .then(data => {
                setImages(data);
                imagess = data ;
                uploadVideos()
            .then(data =>{
                setVideos(data);
                videoss = data ;
                uploadCerts()
              .then(data =>{
                setCertimgs(data);
                certimgss = data ;
                var gemm = JSON.stringify({
                  "seller": seller,
                  "type": type,
                  "weight": weight,
                  "shape": shape,
                  "width": width,
                  "length": length,
                  "depth": depth,
                  "color": color,
                  "clarity": clarity,
                  "origin": origin,
                  "treatment": treatment,
                  "certificate": certificate,
                  "price":price,
                  "priceOption":priceOption,
                  "images": imagess,
                  "videos": videoss,
                  "certimgs": certimgss,
                  "title":title,
                  "description":description

                });
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body:gemm,
                  redirect: 'follow'
              };
              fetch("https://app.gemnoble.com:2005/gems/create", requestOptions)
              .then(
                  response => response.json()
              )
              .then(result => {
                  //alert(result['message']);
                  setStatus("Posted Successfully ID : "+ result['id']);
                  setIsUploaded(true);
                  if (result['status'] === 'ok') {
                      //window.location.href = '/update/' + result['id'];
                      setListId(result['id']);
                  }
              })
              .catch(error =>  setStatus('error', error));
                
              })
              .catch(error => {
                console.error(error);
              })
            })
            .catch(error => {
              console.error(error);
            })
          })
          .catch(error => {
            console.error(error);
          })

          
        }
        
    }





    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>

                <Box display="flex">
                    <Box sx={{
                            flexGrow: 1
                        }}>
                        <Typography variant="h6" gutterBottom>
                            Create Gems
                        </Typography>
                    </Box>

                </Box>

                <form id="gem-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    minWidth: 120
                                }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Seller</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="seller-select"
                                        value={seller}
                                        label="Seller"
                                        required
                                        onChange={(e) => setSeller(e.target.value)}>
                                        {
                                            sellerLists.map((sellerlist) => (
                                                <MenuItem key={sellerlist.key} value={sellerlist.key}>{sellerlist.value}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    minWidth: 120
                                }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" required>Gemtype</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type-select"
                                        value={type}
                                        label="Gemtype"
                                        required
                                        onChange={(e) => setType(e.target.value)}>
                                        {
                                            gemtypeLists.map((gemtypeLists) => (
                                                <MenuItem key={gemtypeLists.key} value={gemtypeLists.key}>{gemtypeLists.value}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                    
                                    

                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="weight"
                                value={weight}
                                type="number"
                                label="Gem Weight"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Ct</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setWeight(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Shape</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="shape-select"
                                    value={shape}
                                    label="Seller"
                                    required
                                    onChange={(e) => setShape(e.target.value)}>
                                    {
                                        shapeLists.map((shapelist) => (
                                            <MenuItem key={shapelist.key} value={shapelist.key}>{shapelist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="width"
                                value={width}
                                type="number"
                                label="Width"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setWidth(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="length"
                                value={length}
                                type="number"
                                label="Length"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setLength(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="depth"
                                value={depth}
                                type="number"
                                label="Depth"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth
                                required
                                onChange={(e) => setDepth(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="color-select"
                                    value={color}
                                    label="Color"
                                    required
                                    onChange={(e) => setColor(e.target.value)}>
                                    {
                                        colorLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Clarity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="clarity-select"
                                    value={clarity}
                                    label="Clarity"
                                    required
                                    onChange={(e) => setClarity(e.target.value)}>
                                    {
                                        clarityLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Origin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="origin-select"
                                    value={origin}
                                    label="Origin"
                                    required
                                    onChange={(e) => setOrigin(e.target.value)}>
                                    {
                                        originLists.map((originlist) => (
                                            <MenuItem key={originlist.key} value={originlist.key}>{originlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Treathment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="treathment-select"
                                    value={treatment}
                                    label="Treathment"
                                    required
                                    onChange={(e) => setTreatment(e.target.value)}>
                                    {
                                        treatmentLists.map(
                                            (heatlist) => (<MenuItem key={heatlist.key} value={heatlist.key}>{heatlist.value}</MenuItem>)
                                        )
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="certificate-multiple-checkbox"
                                label="Certificate"
                                multiple
                                fullWidth
                                value={certificate}
                                onChange={(e) => {
                                  const {target: {
                                          value
                                      }} = e;
                                  setCertificate(
                                      // On autofill we get a stringified value.
                                          typeof value === 'string'
                                          ? value.split(',')
                                          : value,
                                  );
                              }}
                                input={<OutlinedInput label = "" />}
                                renderValue={(selected) => selected.join(', ')}>
                                {
                                    certs.map((cert) => (
                                        <MenuItem key={cert} value={cert}>
                                            <Checkbox checked={certificate.indexOf(cert) > -1}/>
                                            <ListItemText primary={cert}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    onChange={e=>{setPrice(e.target.value);
                                        setTitle(CreateTitle(gem));
                                        setDescription(CreateParagrahp(gem));
                                        
                                    }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                        <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Price Option</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={priceOption}
                            onChange={handlePriceOption}
                        >
                            <FormControlLabel value="open" control={<Radio />} label="Open Price" />
                            <FormControlLabel value="sale" control={<Radio />} label="Sale Price" />
                            
                        </RadioGroup>
                        </FormControl>

                        </Grid>
                        

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Images Upload
                                </Typography>
                                <input
                                    type="file"
                                    name="file"
                                    multiple
                                    accept=".JPG"
                                    onChange={handleSelectImages}></input>
                                {
                                    isImagesPicked
                                        ? (

                                            <ImageList
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                cols={3}
                                                rowHeight={164}>
                                                {
                                                    imageFilesSelect.map((item) => (
                                                        <ImageListItem key={item.name}>
                                                            <img src={URL.createObjectURL(item)}/>
                                                        </ImageListItem>
                                                    ))
                                                }
                                            </ImageList>

                                        )
                                        : (<div></div>)
                                }

                                

                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Videos Upload
                                </Typography>
                                <input
                                    type="file"
                                    name="file"
                                    multiple
                                    accept=".mp4"
                                    onChange={handleSelectVideos}></input>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>

                                    {
                                        videoFilesSelect.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}>

                                                <CardCover>
                                                    <video autoPlay="autoPlay" loop="loop" muted="muted">
                                                        <source src={URL.createObjectURL(item)} type="video/mp4"/>
                                                    </video>
                                                </CardCover>

                                            </Card>
                                        ))
                                    }
                                </Box>
                                
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Certificates Upload
                                </Typography>
                                <input type="file" name="file" accept=".JPG" multiple onChange={handleSelectCerts}></input>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>

                                    {
                                        certimgFilesSelect.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}>

                                                <CardCover>
                                                    <img
                                                        src={URL.createObjectURL(item)}
                                                        srcSet={URL.createObjectURL(item)}
                                                        loading="lazy"
                                                        alt=""/>
                                                </CardCover>

                                            </Card>
                                        ))
                                    }
                                </Box>
                                
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey',
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Create title and description
                                </Typography>
                                <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                component="ul"
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                    p: 0,
                                    m: 0
                                }}>
                                    <TextField
                                    fullWidth 
                                    id="outlined-multiline-flexible"
                                    label="Title"
                                    multiline
                                    maxRows={4}
                                    value={title}
                                    error={isTitleMorethan80(title)}
                                    
                                    onChange={e=>{setTitle(e.target.value);}}
                                    />
                                    </Box></Container>

                                    <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>
                                        <TextField
                                        fullWidth
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        
                                        value={description}
                                        onChange={e=>{setDescription(e.target.value);}}
                                        />
                                        </Box></Container>

                                
                        
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                          <Typography>
                            {status}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          {isUploading?(<LinearProgress />):(<div></div>)}
                          
                        </Box>

                          
                        </Grid>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={12}>
                            {
                                isUploaded?
                                (<Button  variant='contained' fullWidth onClick={e=>{e.preventDefault();window.location.href = '/update/'+listedId;}}>View</Button>)
                                :
                                (<Button type="submit" variant='contained' fullWidth>Post</Button>)
                            }
                        

                        </Grid>

                        

                        <Grid item xs={12}>

                            
                                {true?(<div></div>):(<Button
                                onClick={handleConsolelog}
                                variant='contained'
                                fullWidth
                                
                                >CONSOLELOG</Button>)}
                        </Grid>

                        

                    </Grid>
                </form>

            </Container>
        </React.Fragment>
    );
}
// Import necessary lists from the utility module
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';

/**
 * Create a paragraph description for a gemstone.
 * @param {Object} gem - The gemstone object containing details.
 * @returns {string} - A descriptive paragraph about the gemstone.
 */
export function CreateParagrahp(gem) {
    const gemType = gemtypeLists.find(item => item.key === gem.type).value;
    const origin = originLists.find(item => item.key === gem.origin).value;
    const color = colorLists.find(item => item.key === gem.color).value;
    const shape = shapeLists.find(item => item.key === gem.shape).value;

    return `This Natural ${gemType} originates from ${origin} and it weighs ${gem.weight} carats. 
            This ${color}, ${shape} shape stone, is size ${gem.width} x ${gem.length} x ${gem.depth} mm. 
            Thanks again! We look forward to serving you.`;
}

/**
 * Generate a random title for a gemstone based on its attributes.
 * @param {Object} gem - The gemstone object containing details.
 * @returns {string} - A randomly generated title for the gemstone.
 */
export function CreateTitle(gem) {
    function randomPrefix() {
        const prefixList = [
            "Ravishing", "Amazing", "Striking", "Gorgeous", "Lovely", "Beautiful",
            "Attractive", "Elegant", "Dazzling", "Twinkling", "Nice", "Marvelous",
            "Stunning", "Wonderful", "Exquisite", "Captivating", "Bewitching"
        ];
        return prefixList[Math.floor(Math.random() * prefixList.length)];
    }
    const certificate = ()=>{
        if (gem.certificate.length>0){
            return gem.certificate[0] + " CERTIFICATE Incl.* "
        } else {
            return ""
        }
    }

    const shape = shapeLists.find(item => item.key === gem.shape).value;
    const color = colorLists.find(item => item.key === gem.color).value;
    const gemType = gemtypeLists.find(item => item.key === gem.type).value;
    const origin = originLists.find(item => item.key === gem.origin).value;
    

    return `${certificate()}${randomPrefix()} ${gem.weight}ct ${shape} ${color} Natural ${gemType} ${origin}`;
}

/**
 * Create the HTML description for eBay listings.
 * @param {Object} gem - The gemstone object containing details.
 * @param {Boolean} track - if True will be add PixelTrack code into HTML Description
 * @returns {string} - The complete HTML description for the eBay listing.
 */
export function CreateEbayDescription(gem,track) {
    const title = gem.title;
    const paragraph = gem.description;
    const PixelTrack = track?(`<img src="https://app.gemnoble.com:2005/pixel/${gem.id}/" />`):(``);

    function imgTag(gem) {
        let imgstag = "";
        let count = 0;
        let len = gem.images.length;

        for (let i = 0; i < Math.ceil(gem.images.length / 2); i++) {
            let row = "";
            for (let j = 0; j < Math.min(len, 2); j++) {
                row += `
                    <div class="col-12 col-md-6" style="padding-bottom: 40px">
                        <font face="Arial">
                            <img alt="Image" class="mx-auto d-block img-fluid" src="${gem.images[count + j].source_url}" style="width: 90%" title="${gem.description}" />
                        </font>
                    </div>`;
                len -= 1;
            }
            count += 2;
            imgstag += `
                <div class="photos${i}" style="border-left: 1px solid #283055; border-right: 1px solid #283055; color: #273C2C; line-height: 25px">
                    <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                        <div class="row">
                            ${row}
                        </div>
                    </div>
                </div>`;
        }

        return imgstag;
    }

    function createVideosList(gem) {
        let imgstag = "";
        let count = 0;
        let k = 1;
        let len = gem.videos.length - 1;
        const step = Math.ceil(len / 2);

        for (let i = 0; i < step; i++) {
            let row = "";
            for (let j = 0; j < Math.min(len, 2); j++) {
                row += `
                    <div class="col-12 col-md-6" style="padding-bottom: 40px" align="center">
                        <video controls="controls" width="400" height="400" autoplay="false" loop="true">
                            <source src="${gem.videos[k].source_url}" type="video/mp4">
                        </video>
                    </div>`;
                len -= 1;
                k++;
            }
            imgstag += `
                <div class="Videos" style="border-left: 1px solid #283055; border-right: 1px solid #283055; color: #273C2C; line-height: 25px">
                    <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                        <div class="row">
                            ${row}
                        </div>
                    </div>
                </div>`;
        }

        return imgstag;
    }

    function createCertificatesList(gem) {
        let certstag = "";
        for (let i = 0; i < gem.certimgs.length; i++) {
            certstag += `
                <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                    <img alt="Image" class="mx-auto d-block img-fluid" src="${gem.certimgs[i].source_url}" style="width: 90%" title="Image" />
                </div>`;
        }
        return certstag;
    }

    function createCetificateAttribute(gem) {
        return gem.certificate.length <= 0 ? '' : `<li><b>Certificate:</b> ${gem.certificate} </li>`;
    }

    return `<!doctype html>
        <html lang="zxx">
        <head>
        </head>
        <body>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        </body>
        </html>
        <title>${title}</title>
        <link href="https://dewiso.com/css/bootstrap.min.css" rel="stylesheet" />
        <style type="text/css">
            .element {
                background: #0a559e
            }
        </style>
        <div class="element" style="border-left: 1px solid #283055; border-right: 1px solid #283055; border-top: 1px solid #F4F6F7; color: #283055; border-bottom: 1px solid #283055; color: #fff; line-height: 45px">
            <div class="container" style="padding: 40px 0 40px 0">
                <div class="col-12 col-md-12">
                    <h3 style="text-align: center"><strong>${title}</strong></h3>
                </div>
            </div>
        </div>
        <div class="paragraph" style="border-left: 1px solid #283055; border-right: 1px solid #283055; color: #273C2C; line-height: 25px">
            <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                <p>${paragraph}</p>
            </div>
        </div>
        <div class="itemspec" style="border-left: 1px solid #283055; border-right: 1px solid #283055; color: #273C2C; line-height: 25px">
            <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                <div class="row">
                    <div class="col-12 col-md-6" style="padding-bottom: 40px" align="center">
                        <font face="Arial" size="-5">
                            <video controls="controls" width="400" height="400" autoplay="true" loop="true">
                                <source src="${gem.videos[0].source_url}" type="video/mp4">
                            </video>
                            <p align="center" style="color: #e1343d"><b>PICTURE & VIDEO IS THE ACTUAL ITEM FOR SALE. COPYRIGHT BY GEMNOBLE AND THE WINNER OF THIS ITEM.</b></p>
                        </font>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="col-12 col-md-12">
                            <h2 style="color: #283055; line-height: 45px">Item specifics</h2>
                            <ul style="color: #273C2C; line-height: 25px; margin-top: 30px">
                                <li><b>Gem Type:</b> Natural ${gemtypeLists.find(item => item.key === gem.type).value}</li>
                                <li><b>Weight:</b> ${gem.weight} Carat</li>
                                <li><b>Shape:</b> ${shapeLists.find(item => item.key === gem.shape).value}</li>
                                <li><b>Size:</b> ${gem.width} x ${gem.length} x ${gem.depth} mm.</li>
                                <li><b>Color:</b> ${colorLists.find(item => item.key === gem.color).value}</li>
                                <li><b>Clarity:</b> ${clarityLists.find(item => item.key === gem.clarity).value}</li>
                                <li><b>Origin:</b> ${originLists.find(item => item.key === gem.origin).value}</li>
                                <li><b>Treatment:</b> ${treatmentLists.find(item => item.key === gem.treatment).value}</li>
                                ${createCetificateAttribute(gem)}
                                <li><b>SKU:</b> ${gem.id}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ${imgTag(gem)}
        ${createVideosList(gem)}
        <div class="Certificate" style="border-left: 1px solid #283055; border-right: 1px solid #283055; color: #273C2C; line-height: 25px">
            <div class="container" style="padding-bottom: 20px; padding-top: 50px">
                ${createCertificatesList(gem)}
            </div>
        </div>
        <div class="storepolicy" style="border-left: 1px solid #283055; border-right: 1px solid #283055; border-bottom: 1px solid #283055; color: #273C2C; line-height: 25px">
            <div class="container" style="padding: 40px 0 40px 0">
                <h2 style="color: #283055; line-height: 45px" align="center">
                    <font face="Arial">Term of Service</font>
                </h2>
                <table class="table table-responsive table-borderless" style="color: #273C2C; line-height: 25px; margin-top: 30px">
                    <tbody>
                        <tr>
                            <th scope="row" style="color: #0a559e"><p><b>Payment</b></p></th>
                            <td>We Accept payments method managed by <strong style="color: red">e</strong><strong style="color: blue">B</strong><strong style="color: orange">a</strong><strong style="color: mediumseagreen">y</strong>.</td>
                        </tr>
                        <tr>
                            <th scope="row" style="color: #0a559e"><p><b>Shipping</b></p></th>
                            <td>
                                <p>Below you will find our Shipping Options:</p>
                                <ui>
                                    <li><strong style="color: hotpink">Economy Shipping :</strong> Provided by <strong style="color: blue">THAILAND POST</strong> via e-package service</li><br>
                                        <p>&nbsp - Shipping items in envelopes or boxes (Package) weighing not more than 2 kilograms,<br>&nbsp &nbsp except for destinations in the UK, can deliver up to 5 kilograms.</p>
                                        <p>&nbsp - Standard delivery to recipient abroad within 10 – 20 days, depending on the destination country.</p>
                                        <p><small style="color: red">***Note: If there is an emergency or disaster situation May affect delivery standards in some areas. This results in delays beyond the set standards***</small></p>
                                    <li><strong style="color: hotpink">Expedited Shipping :</strong> Provided by <strong style="color: rebeccapurple">Fed</strong><strong style="color: darkorange">Ex</strong> Express</li><br>
                                        <p>&nbsp - Shipping items in envelopes weighing not more than 0.5 kilograms.</p>
                                        <p>&nbsp - Standard delivery to recipient abroad within 4-7 days, depending on the destination country.</p>
                                </ui>
                                <br>
                                <p><strong style="color: green">Combining : </strong> free for additional items. Maximum combination of 40 items per invoice/per shipment. 7 days combine shopping period from first purchase.</p>
                                <p>Our Shipping service Price Please look at "Shipping and payments" Tab.</p>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" style="color: #0a559e"><p><b>Returns and Refund</b></p></th>
                            <td>We hope you are satisfied with our products. However, if you are not satisfied, we are happy to offer a full refund within 14 days from the date of delivery. Returned items must be in the same original condition as we were delivered to you. Please <a href="https://www.ebay.com/contact/sendmsg?message_type_id=14&recipient=gemnoble"> contact us</a> to ask for Return Instructions. The shipping fees are not refundable. All returned merchandise must be sent by registered mail (FedEx, UPS, DHL, etc.) with tracking number. Appropriate return packaging and registered mail fees are the responsibility of the customer returning the merchandise.
                            <small style="color: red">Note: Buyer must notify us about return within 2 days from the moment of item delivery.</small></td>
                        </tr>
                        <tr>
                            <th scope="row" style="color: #0a559e"><p><b>Feedback</b></p></th>
                            <td>We do our best to make your purchase as comfortable as possible. If you are satisfied with your purchase, please leave us positive feedback. However, if you are not satisfied with your purchase, please do not leave any negative or neutral feedback before you give us the opportunity to solve your problems or any issues. Please <a href="https://www.ebay.com/contact/sendmsg?message_type_id=14&recipient=gemnoble"> contact us</a> We will try our best!</td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-12 col-md-12">
                    <h2 style="color: #283055; line-height: 45px" align="center">
                        <font face="Arial">Contact us</font>
                    </h2>
                    <p style="color: #273C2C;">You can contact us any time through <a href="https://www.ebay.com/contact/sendmsg?message_type_id=14&recipient=gemnoble">eBay messages</a> if you have any questions, comments, or product requests. We will reply to you within 24 hours and do our best to help you out! We encourage our customers to contact us with any questions or concerns! We&#39;d like to be sure you are completely satisfied with your purchase.</p>
                    ${PixelTrack}
                </div>
            </div>
        </div>
        <div class="col-12 col-md-12 element" style="border-top: 1px solid #283055; color: #fff; line-height: 25px">
            <div class="container" style="padding: 20px 0 10px 0; font-size: 80%; text-align: center">
                <p><font face="Arial">Buy with confidence, feel free to contact us, thank you and you're welcome. Have a nice day.</font></p>
            </div>
        </div>
    </div>`;
}

/**
 * Create a description for WooCommerce listings.
 * @param {Object} gem - The gemstone object containing details.
 * @returns {string} - The complete HTML description for WooCommerce listing.
 */
export function CreateWooDescription(gem) {
    function createCetificateAttributeWoo(gem) {
        return gem.certificate.length <= 0 ? '' : `\n<strong>Certificate: ${gem.certificate} </strong>`;
    }
    return `<p>${gem.description}</p>
        <strong>Gemstone Description:</strong>
        <strong>Type: ${gemtypeLists.find(item => item.key === gem.type).value}</strong>
        <strong>Weight: ${gem.weight}</strong>
        <strong>Shape: ${shapeLists.find(item => item.key === gem.shape).value}</strong>
        <strong>Size: ${gem.width} x ${gem.length} x ${gem.depth}</strong>
        <strong>Color: ${colorLists.find(item => item.key === gem.color).value}</strong>
        <strong>Clarity: ${clarityLists.find(item => item.key === gem.clarity).value}</strong>
        <strong>Origin: ${originLists.find(item => item.key === gem.origin).value}</strong>
        <strong>Treatment: ${treatmentLists.find(item => item.key === gem.treatment).value}</strong>${createCetificateAttributeWoo(gem)}
        <strong>Item Number: ${gem.id}</strong>`;
}

/**
 * Create the XML structure for eBay listing submission.
 * @param {Object} gem - The gemstone object containing details.
 * @param {string} token - The eBay authentication token.
 * @returns {string} - The XML structure for eBay listing submission.
 */
export function createXmlEbay(gem, token) {
    let imgurl = "";
    for (let i = 0; i < gem.images.length; i++) {
        imgurl += `<PictureURL>${gem.images[i].source_url}</PictureURL>\n`;
    }

    return `<?xml version="1.0" encoding="utf-8"?>
        <AddItemRequest xmlns="urn:ebay:apis:eBLBaseComponents">
            <RequesterCredentials>
                <eBayAuthToken>${token}</eBayAuthToken>
            </RequesterCredentials>
            <ErrorLanguage>en_US</ErrorLanguage>
            <WarningLevel>High</WarningLevel>
            <Item>
                <Title>${gem.woo.name}</Title>
                <Description><![CDATA[${CreateEbayDescription(gem,true)}]]></Description>
                <PrimaryCategory>
                    <CategoryID>262027</CategoryID>
                </PrimaryCategory>
                <StartPrice>${gem.price}</StartPrice>
                <CategoryMappingAllowed>true</CategoryMappingAllowed>
                <ConditionID>1500</ConditionID>
                <Country>TH</Country>
                <Currency>USD</Currency>
                <DispatchTimeMax>3</DispatchTimeMax>
                <ListingDuration>Days_7</ListingDuration>
                <ListingType>Chinese</ListingType>
                <Location>Thailand</Location>
                <PictureDetails>${imgurl}</PictureDetails>
                <PostalCode>22000</PostalCode>
                <Quantity>1</Quantity>
                <ItemSpecifics>
                    <NameValueList>
                        <Name>Gemstone Type</Name>
                        <Value>${gemtypeLists.find(item => item.key === gem.type).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Total Carat Weight (TCW)</Name>
                        <Value>${gem.weight}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Gemstone Shape</Name>
                        <Value>${shapeLists.find(item => item.key === gem.shape).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Gemstone Color</Name>
                        <Value>${colorLists.find(item => item.key === gem.color).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Country of Origin</Name>
                        <Value>${originLists.find(item => item.key === gem.origin).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Brand</Name>
                        <Value>Gemnoble</Value>
                    </NameValueList>
                </ItemSpecifics>
                <Quantity>1</Quantity>
                <SellerProfiles>
      		        <SellerShippingProfile>
       			        <ShippingProfileID>305171173023</ShippingProfileID>
    		  	    </SellerShippingProfile>
      		        <SellerReturnProfile>
        			    <ReturnProfileID>298814943023</ReturnProfileID>
      		        </SellerReturnProfile>
      		        <SellerPaymentProfile>
        			    <PaymentProfileID>298814233023</PaymentProfileID>
      		    </SellerPaymentProfile>
                </SellerProfiles>
                <Site>US</Site>
            </Item>
        </AddItemRequest>`;
}

/**
 * Create the XML structure for eBay listing submission.
 * @param {Object} gem - The gemstone object containing details.
 * @returns {string} - The XML Item for create xml structure for eBay listing submission.
 */
export function createXmlItem(gem) {
    let imgurl = "";
    for (let i = 0; i < gem.images.length; i++) {
        imgurl += `<PictureURL>${gem.images[i].source_url}</PictureURL>\n`;
    }

    return `
            <Item>
                <Title>${gem.title}</Title>
                <Description><![CDATA[${CreateEbayDescription(gem,true)}]]></Description>
                <PrimaryCategory>
                    <CategoryID>262027</CategoryID>
                </PrimaryCategory>
                <StartPrice>${gem.price}</StartPrice>
                <CategoryMappingAllowed>true</CategoryMappingAllowed>
                <ConditionID>1500</ConditionID>
                <Country>TH</Country>
                <Currency>USD</Currency>
                <DispatchTimeMax>3</DispatchTimeMax>
                <ListingDuration>Days_7</ListingDuration>
                <ListingType>Chinese</ListingType>
                <Location>Thailand</Location>
                <PictureDetails>${imgurl}</PictureDetails>
                <PostalCode>22000</PostalCode>
                <Quantity>1</Quantity>
                <ItemSpecifics>
                    <NameValueList>
                        <Name>Gemstone Type</Name>
                        <Value>${gemtypeLists.find(item => item.key === gem.type).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Total Carat Weight (TCW)</Name>
                        <Value>${gem.weight}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Gemstone Shape</Name>
                        <Value>${shapeLists.find(item => item.key === gem.shape).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Gemstone Color</Name>
                        <Value>${colorLists.find(item => item.key === gem.color).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Country of Origin</Name>
                        <Value>${originLists.find(item => item.key === gem.origin).value}</Value>
                    </NameValueList>
                    <NameValueList>
                        <Name>Brand</Name>
                        <Value>Gemnoble</Value>
                    </NameValueList>
                </ItemSpecifics>
                <Quantity>1</Quantity>
                <SellerProfiles>
      		        <SellerShippingProfile>
       			        <ShippingProfileID>305171173023</ShippingProfileID>
    		  	    </SellerShippingProfile>
      		        <SellerReturnProfile>
        			    <ReturnProfileID>298814943023</ReturnProfileID>
      		        </SellerReturnProfile>
      		        <SellerPaymentProfile>
        			    <PaymentProfileID>298814233023</PaymentProfileID>
      		    </SellerPaymentProfile>
                </SellerProfiles>
                <Site>US</Site>
            </Item>
        `;
}

/**
 * Set price options for the item in WooCommerce.
 * @param {string} priceOption - The selected pricing option ('open' or 'sale').
 * @returns {Object} - The corresponding configuration object.
 */
export function PriceOption(priceOption) {
    if (priceOption === 'open') {
        return {
            key: "offers_for_woocommerce_enabled",
            value: "yes"
        };
    } else if (priceOption === 'sale') {
        return {
            key: "offers_for_woocommerce_enabled",
            value: "no"
        };
    }
}




import { Box,Button, Grid,Typography  } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React, {useState,useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
export default function FileUpload(){

    const [selectedFile, setSelectedFile] = useState([]);
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [upLoaded, setUploaded] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const imgList = [];
 
    const handleSelectFile = (e) => {
        setIsUploading(false)
        setIsFilePicked(true)
        //Convert File object to Array Using Array.prototype.slice.call(e.target.files)
        setSelectedFile(Array.prototype.slice.call(e.target.files))
        console.log(Array.prototype.slice.call(e.target.files))
        
	} ;

    const handleUpload = async () => {
		for (let i = 0; i < selectedFile.length; i++) {
            setIsUploading(true)
            console.log("Start Upload : "+selectedFile[i].name)
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "image/jpeg");
			myHeaders.append("Content-Disposition", "form-data; filename=\""+selectedFile[i].name+"\"");
			myHeaders.append("Authorization", "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg==");
			var requestOptions = {
			  method: 'POST',
			  headers: myHeaders,
			  body: selectedFile[i],
			  redirect: 'follow'
			};
			await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
			  .then(response => response.text())
			  .then(result => { 
               // console.log(JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url)
                //imgList.push({img:JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url})
                imgList.push(JSON.parse(result))
                console.log("Uploaded : "+selectedFile[i].name+" URL:"+JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url)
                setIsUploading(false)
				})
			  .catch(error => console.log('error', error));
			  
		  }
          console.log(imgList)

	}



	const handleUploadold = () => {
		for (let i = 0; i < selectedFile.length; i++) {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "image/jpeg");
			myHeaders.append("Content-Disposition", "form-data; filename=\""+selectedFile[i].name+"\"");
			myHeaders.append("Authorization", "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg==");
			var requestOptions = {
			  method: 'POST',
			  headers: myHeaders,
			  body: selectedFile[i],
			  redirect: 'follow'
			};
			fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
			  .then(response => response.text())
			  .then(result => { 
				//console.log([...itemData,{img:JSON.parse(result).media_details.sizes.thumbnail.source_url}])
                console.log(JSON.parse(result).media_details.sizes.thumbnail.source_url)
                //setUploaded([...upLoaded,{img:JSON.parse(result).media_details.sizes.thumbnail.source_url}])
                
					
				})
			  .catch(error => console.log('error', error));
			  
		  }
		//setSelectedFile(e.target.files[0]);
		//setIsFilePicked(true);
		//console.log(e.target.files[0])
        
		

	}


	return(
        
      <div>
		 <CssBaseline />
      		<Container maxWidth="lg" sx={{p:2,border: '1px dashed grey'}} >

                    <Typography variant='h6' gutterBottom component='div'>
                        Product Images
                    </Typography>

                    <Grid >
                            {isFilePicked ? (
                                        <div></div>
                                        ) : (
                                            <input type="file" name="file" multiple  onChange={handleSelectFile}></input>
                                        )}

                            {isFilePicked ? (
                                        <ImageList  sx={{ width: 500, height: 500 }} cols={3} rowHeight={164}>
                                            {selectedFile.map((item) => (
                                                <ImageListItem key={item.name}>
                                                    <img src={URL.createObjectURL(item)}/>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                        ) : (
                                            <div></div>
                                        )}

                            {isFilePicked ? (
                                        
                                        <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Type</TableCell>
                                                <TableCell align="right">Size</TableCell>
                                                
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {selectedFile.map((item) => (
                                                <TableRow
                                                key={item.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                <TableCell component="th" scope="row">{item.name}</TableCell>
                                                <TableCell align="right">{item.type}</TableCell>
                                                <TableCell align="right">{(Number(item.size)/1024).toFixed(2)}</TableCell>
                                                
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        ) : (
                                            <div></div>
                                        )}


                                <Box sx={{ display: 'flex' }}>
                                {isUploading ? (
                                    <div>
                                        <Typography variant='h6' gutterBottom component='div'>
                                            Uploading ...
                                        </Typography>
                                    <CircularProgress />
                                    </div>
                                    ) : (
                                        <div>
                                            
                                        </div>
                                    )}
                                </Box>   
                            <Box spacing={2}>
                            {isFilePicked ? (
                                        <div >
                                            <Button  variant="contained"  onClick={handleUpload} >Upload Images</Button>
                                        </div>
                                        ) : (
                                            <div>

                                            </div>
                                            
                                        )}

                                </Box>

                </Grid>
            
			</Container>

		</div>
	)
}